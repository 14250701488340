import { InboxIcon, SparklesIcon } from "@heroicons/react/24/outline";
import self from "../../images/self.jpg";
import couple from "../../images/couple.jpg";
import family from "../../images/family.jpg";
import marce from "../../images/customers/marce.jpg";
import peter from "../../images/customers/peter.webp";

export default function Features() {
  return (
    <div
      id="terapias"
      className="relative overflow-hidden bg-white pt-16 pb-32"
    >
      <div className="relative">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0">
            <div>
              <div>
                <span className="flex h-12 w-12 items-center justify-center rounded-xl bg-indigo-600">
                  <InboxIcon
                    className="h-8 w-8 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Terapias Individuales
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Ofrecemos terapia individual para niños, jóvenes y adultos.
                  Nuestro enfoque es multidisciplinario y adaptado a las
                  necesidades de cada persona. Utilizamos enfoques sistémicos,
                  cognitivo-conductuales y transpersonales y podemos incluir
                  hipnosis clínica. Nuestro objetivo es ayudar a los clientes a
                  superar problemas y mejorar su bienestar mental.
                </p>
                <div className="mt-6">
                  <a
                    href="#precios"
                    className="inline-flex rounded-lg bg-indigo-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-indigo-600 hover:bg-indigo-700 hover:ring-indigo-700"
                  >
                    Agendar cita
                  </a>
                </div>
              </div>
            </div>
            <div className="mt-8 border-t border-gray-200 pt-6">
              <blockquote>
                <div>
                  <p className="text-base text-gray-500">
                    &ldquo;Hola espero que estén super bien, pasó por acá a
                    dejarles las gracias ya que sin ustedes, mi cambio nunca
                    hubiera pasado 💞🙏 &rdquo;
                  </p>
                </div>
                <footer className="mt-3">
                  <div className="flex items-center space-x-3">
                    <div className="flex-shrink-0">
                      <img
                        className="h-6 w-6 rounded-full"
                        src={marce}
                        alt=""
                      />
                    </div>
                    <div className="text-base font-medium text-gray-700">
                      Marcela Perez
                      <div className="text-sm text-gray-400">
                        Servicio terapia individual
                      </div>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src={self}
                alt="Inbox user interface"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:py-32 lg:px-0">
            <div>
              <div>
                <span className="flex h-12 w-12 items-center justify-center rounded-xl bg-indigo-600">
                  <SparklesIcon
                    className="h-8 w-8 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Terapias de Parejas
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Brindamos terapia de pareja adaptada a las necesidades
                  específicas de cada pareja. Nuestro enfoque es
                  multidisciplinario y puede incluir técnicas sistémicas,
                  cognitivo-conductuales y transpersonales, así como hipnosis
                  clínica. Nuestro objetivo es ayudar a las parejas a
                  desarrollar mejores habilidades de comunicación y mejorar su
                  relación.
                </p>
                <div className="mt-6">
                  <a
                    href="#precios"
                    className="inline-flex rounded-lg bg-indigo-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-indigo-600 hover:bg-indigo-700 hover:ring-indigo-700"
                  >
                    Agenda aquí
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
            <div className="-ml-48 pr-4 sm:pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                src={couple}
                alt="Customer profile user interface"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative pt-16">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0">
            <div>
              <div>
                <span className="flex h-12 w-12 items-center justify-center rounded-xl bg-indigo-600">
                  <InboxIcon
                    className="h-8 w-8 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Terapias Familiares
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Ofrecemos terapia familiar para abordar problemas que afectan
                  la dinámica familiar. Nuestro enfoque se adapta a las
                  necesidades de cada familia y utilizamos enfoques sistémicos,
                  cognitivo-conductuales y transpersonales, así como hipnosis
                  clínica. Nuestro objetivo es mejorar la comunicación,
                  fortalecer las relaciones y ayudar a las familias a superar
                  desafíos.
                </p>
                <div className="mt-6">
                  <a
                    href="#precios"
                    className="inline-flex rounded-lg bg-indigo-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-indigo-600 hover:bg-indigo-700 hover:ring-indigo-700"
                  >
                    Agendar cita
                  </a>
                </div>
              </div>
            </div>
            <div className="mt-8 border-t border-gray-200 pt-6">
              <blockquote>
                <div>
                  <p className="text-base text-gray-500">
                    &ldquo;Hola espero que estén super bien, pasó por acá a
                    dejarles las gracias ya que sin ustedes, nuestra familia
                    nunca se hubiera vuelto a unir. Ahora podemos hablar y
                    resolver los problemas entre nosotros, parece tan sencillo!
                    pero nunca antes lo habíamos logrado. Gracias de 💞🙏
                    &rdquo;
                  </p>
                </div>
                <footer className="mt-3">
                  <div className="flex items-center space-x-3">
                    <div className="flex-shrink-0">
                      <img
                        className="h-6 w-6 rounded-full"
                        src={peter}
                        alt=""
                      />
                    </div>
                    <div className="text-base font-medium text-gray-700">
                      Pedro Sanhueza
                      <div className="text-sm text-gray-400">
                        Servicio de terapia familiar
                      </div>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src={family}
                alt="Inbox user interface"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
