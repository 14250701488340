import { StarIcon } from "@heroicons/react/20/solid";
import life from "../../images/life.jpg";

export default function About() {
  return (
    <div
      id="enfoque"
      className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0"
    >
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute top-0 left-[max(50%,25rem)] h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth={0}
            fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
          />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">
                Enfoque Terapéutico
              </p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Una forma diferente de ver la vida
              </h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
                Nuestro enfoque es ayudar a los clientes a desprenderse de sus
                "patologías o disfunciones", aceptarse a sí mismos y encontrar
                su lugar en la vida. Utilizamos un enfoque holístico que incluye
                varios procesos de atención psicológica, como Psicología, Reiki,
                Hipnosis Clínica, Coaching, Hipno coaching y más. Nuestro
                objetivo es ayudar a los clientes a encontrar sus propias
                habilidades naturales y recursos para la autoasistencia.
              </p>
            </div>
          </div>
        </div>
        <div className="-mt-12 -ml-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            src={life}
            alt=""
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
                Formación Profesional
              </h2>
              <p>
                Nuestro psicólogo tiene formación académica y profesional en
                psicología de UDLA y también se ha especializado en hipnosis
                clínica en varios centros de formación en Chile. Nuestro
                psicólogo ha trabajado con el renombrado hipnoterapeuta
                argentino Armando Scharovsky y tiene años de experiencia
                ayudando a los clientes a lograr resultados positivos.
              </p>
              <ul role="list" className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <StarIcon
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Psicología
                    </strong>{" "}
                    es la ciencia que estudia la mente, el comportamiento y las
                    emociones humanas para comprender y mejorar la calidad de
                    vida de las personas.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <StarIcon
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Hipnosis
                    </strong>{" "}
                    es una técnica utilizada en psicología para explorar la
                    mente inconsciente y tratar trastornos psicológicos
                  </span>
                </li>

                <li className="flex gap-x-3">
                  <StarIcon
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Reiki
                    </strong>{" "}
                    se utiliza en la psicología holística para tratar el cuerpo,
                    la mente y el espíritu como un todo y mejorar la salud
                    integral.
                  </span>
                </li>
              </ul>

              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
                Motivación constante
              </h2>
              <p className="mt-6">
                Ayudamos a nuestros pacientes a mantenerse motivados y
                comprometidos con su proceso terapéutico haciéndoles ver el
                progreso que han logrado, identificando sus fortalezas y
                estableciendo metas alcanzables. También utilizamos diversas
                técnicas para ayudar a los clientes a superar desafíos y
                mantener su motivación.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
