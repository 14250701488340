const IconRow = ({ icon, text }) => {
  return (
    <div
      className="flex flex-row items-center justify-center mb-4 rounded-md p-3"
      style={{ backgroundColor: "#A29AB0" }}
    >
      <img src={icon} style={styles.icon} />
      <div className="w-4" />
      <p className="text-center text-lg font-semibold" style={styles.data}>
        {text}
      </p>
    </div>
  );
};

export const UnderConstruction = () => {
  return (
    <div
      className="flex-1 items-center justify-items-center h-screen w-screen"
      style={styles.container}
    >
      <div className="mx-auto w-fit">
        <img
          className="mx-auto align-middle"
          style={styles.img}
          src={require("./img/logo.jpg")}
          alt="Under Construction"
        />
        <h1
          className="text-center font-extrabold text-3xl -mt-16"
          style={styles.heading}
        >
          Sitio en Reconstrucción
        </h1>
        <p className="text-center text-lg font-semibold" style={styles.text}>
          Pronto estaremos de vuelta con
        </p>
        <p
          className="text-center text-lg font-semibold -mt-2 mb-4"
          style={styles.text}
        >
          una renovada apariencia
        </p>
        <IconRow text="+56 9 6340 9817" icon={require("./img/whatsapp.png")} />
        <IconRow
          text="info@escueladeterapeutaschile.cl"
          icon={require("./img/envelope.png")}
        />
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#B8AFC8",
  },
  heading: {
    color: "#1C1A1E",
  },
  text: { color: "#686472" },
  data: { color: "#54505B" },
  img: {
    height: 384,
    width: 384,
    margin: 0,
  },
  icon: {
    height: 24,
    width: 24,
    margin: 0,
  },
};
