/* This example requires Tailwind CSS v3.0+ */
import { CheckIcon } from "@heroicons/react/24/outline";
import { openWhatsapp } from "../../utils";

const SecondaryService = ({ title, desc, price, href, sessions }) => {
  return (
    <div className="relative mx-auto mt-8 max-w-7xl px-6 lg:px-8 ">
      <div className="mx-auto max-w-md lg:max-w-4xl rounded-3xl bg-white shadow-xl ring-1 ring-black/10">
        <div className="flex flex-col gap-6 rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10 lg:flex-row lg:items-center lg:gap-8">
          <div className="lg:min-w-0 lg:flex-1">
            <h3 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">
              {title}
            </h3>

            <div className="mt-2 text-base leading-7 text-gray-600 mb-4">
              {desc}{" "}
            </div>
            {sessions && (
              <span className="mr-4 inline-flex items-center rounded-full bg-purple-100 px-3 py-0.5 text-sm font-medium text-purple-800">
                {sessions} {sessions == 1 ? "sesión" : "sesiones"}
              </span>
            )}
            <span className="font-semibold text-gray-900">${price}</span>
          </div>
          <div>
            <a
              href={href}
              className="inline-block rounded-lg bg-indigo-50 px-4 py-2.5 text-center text-sm font-semibold leading-5 text-indigo-700 hover:bg-indigo-100"
            >
              Agendar <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const tiers = [
  {
    id: "Individual",
    name: "Hora Individual",
    href: openWhatsapp("Hora Individual Psicológica"),
    priceMonthly: "30.000",
    description:
      "Se trabaja principalmente a través de hipnosis clínica y sus múltiples recurso. Luego de la entrevista inicial, la sesión se desarrolla en la comodidad de un bergere, apelando a los propios recursos subconscientes del paciente y la conducción profesional de psicólogo.",
    features: [
      "Duración 1 1/2hr cronológica.",
      "Comúnmente utilizada para superar temas psicoemocionales pendientes.",
      "Diálogo interno para fijar objetivos futuros.",
      "Autoconocimiento.",
      "Refuerzos conductuales.",
    ],
  },
  {
    id: "Proceso",
    name: "Proceso Psicológico",
    href: openWhatsapp("Proceso Psicológico"),
    priceMonthly: "180.000",
    description:
      "Este enfoque psicológico combina la planificación acordada y la atención a las necesidades actuales del paciente, utilizando técnicas tradicionales o de hipnosis clínica. El proceso consta de 8 sesiones y se permite la participación de personas relacionadas con el problema. Ofrece más recursos y sesiones más largas a un precio más bajo.",
    features: [
      "Duración 1 1/2hr cronológica.",
      "En primera sesión se efectúa la entrevista inicial y se fijan los objetivos de trabajo.",
      "Sesión a sesión se va avanzando en el o los objetivos fijados.",
      "Muchas veces dependiendo de la temática a tratar, se van entregando tareas a desarrollar.",
      "Las sesiones se van agendando según las necesidades del paciente o las recomendaciones del psicólogo tratante.",
      "Las sesiones contratadas no se pierden.",
    ],
  },
];

export default function Pricing() {
  return (
    <div id="precios" className="bg-gray-900">
      <div className="relative overflow-hidden pt-32 pb-96 lg:pt-40">
        <div>
          <img
            className="absolute bottom-0 left-1/2 w-[1440px] max-w-none -translate-x-1/2"
            src="https://tailwindui.com/img/component-images/grid-blur-purple-on-black.jpg"
            alt=""
          />
        </div>
        <div className="relative mx-auto max-w-7xl px-6 text-center lg:px-8">
          <div className="mx-auto max-w-2xl lg:max-w-4xl">
            <h2 className="text-lg font-semibold leading-8 text-indigo-400">
              Precios
            </h2>
            <p className="mt-2 text-4xl font-bold tracking-tight text-white">
              Planes accesibles para todos los clientes{" "}
            </p>
            <p className="mt-6 text-lg leading-8 text-white/60">
              Todos los precios listados son los establecidos para los servicios
              mencionados
            </p>
          </div>
        </div>
      </div>
      <div className="flow-root bg-white pb-32 lg:pb-40">
        <div className="relative -mt-80">
          <div className="relative z-10 mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2 lg:gap-8">
              {tiers.map((tier) => (
                <div
                  key={tier.name}
                  className="flex flex-col rounded-3xl bg-white shadow-xl ring-1 ring-black/10"
                >
                  <div className="p-8 sm:p-10">
                    <h3
                      className="text-lg font-semibold leading-8 tracking-tight text-indigo-600"
                      id={tier.id}
                    >
                      {tier.name}
                    </h3>
                    <div className="mt-4 flex items-baseline text-5xl font-bold tracking-tight text-gray-900">
                      ${tier.priceMonthly}
                    </div>
                    <p className="mt-6 text-base leading-7 text-gray-600">
                      {tier.description}
                    </p>
                  </div>
                  <div className="flex flex-1 flex-col p-2">
                    <div className="flex flex-1 flex-col justify-between rounded-2xl bg-gray-50 p-6 sm:p-8">
                      <ul role="list" className="space-y-6">
                        {tier.features.map((feature) => (
                          <li key={feature} className="flex items-start">
                            <div className="flex-shrink-0">
                              <CheckIcon
                                className="h-6 w-6 text-indigo-600"
                                aria-hidden="true"
                              />
                            </div>
                            <p className="ml-3 text-sm leading-6 text-gray-600">
                              {feature}
                            </p>
                          </li>
                        ))}
                      </ul>
                      <div className="mt-8">
                        <a
                          href={tier.href}
                          className="inline-block w-full rounded-lg bg-indigo-600 px-4 py-2.5 text-center text-sm font-semibold leading-5 text-white shadow-md hover:bg-indigo-700"
                          aria-describedby={tier.id}
                        >
                          Agenda aquí
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {secondaryServices.map((item) => (
          <SecondaryService
            title={item.title}
            desc={item.desc}
            price={item.price}
            sessions={item.sessions}
            href={item.href}
          />
        ))}
      </div>
    </div>
  );
}

const secondaryServices = [
  {
    title: "Hipnosis clínica terapéutica",
    desc: "La hipnosis se utiliza para liberar conflictos emocionales y buscar el origen de las enfermedades psicosomáticas con el fin de encontrar y retirar la emoción detonante",
    price: "40.000",
    sessions: "1",
    href: openWhatsapp("Hipnosis clínica terapéutica"),
  },
  {
    title: "Terapia de pareja",
    desc: "Terapia guiada por psicólogo experimentado que utiliza diversas dinámicas para identificar y solucionar problemas en la relación. El objetivo es mejorar la calidad de vida en pareja con estabilidad y tranquilidad emocional. La duración del programa y los requisitos son proporcionados.",
    price: "250.000",
    sessions: "12",
    href: openWhatsapp("Terapia de pareja"),
  },
  {
    title: "Terapia contra adicciones",
    desc: "El proceso cuenta con Hipnosis, Coaching y PNL. La primera sesión consiste en una entrevistas más el primer ejercicio breve con hipnosis clínica. Las siguientes 8 sesiones se trabajan con hipnosis clínica.",
    price: "250.000",
    sessions: "9",
    href: openWhatsapp("Terapia contra adicciones"),
  },
];
