import "./App.css";
import LandingPage from "./screens/landing";
import ProductPage from "./screens/product";
import ProductsPage from "./screens/products";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { UnderConstruction } from "./screens/temp";

const router = createBrowserRouter([
  {
    path: "/",
    // element: <UnderConstruction />,
    element: <LandingPage />,
  },
  {
    path: "/landing",
    element: <LandingPage />,
  },
  {
    path: "/product",
    element: <ProductPage />,
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}
