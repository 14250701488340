/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/line-clamp'),
    ],
  }
  ```
*/

import office from "./../../images/office.jpg";
import structure from "./../../images/structure.jpg";
import privacy from "./../../images/privacy.jpg";
const posts = [
  {
    id: 2,
    title: "Estructura de sesión",
    href: "#",
    description:
      "Ofrecemos sesiones personalizadas de duración flexible (1-2½ horas). Iniciamos con una entrevista de información y metas específicas. Trabajamos con diferentes modalidades y adaptamos nuestros métodos a cada persona y proceso único. La complejidad del tema puede afectar la conducta y las modificaciones necesarias.",
    imageUrl: structure,
  },
  {
    id: 1,
    title: "Ambiente cómodo",
    href: "#",
    description:
      "Nos aseguramos de que nuestros pacientes se sientan cómodos y seguros durante las sesiones al permitir que todo fluya naturalmente. Tenemos música de acompañamiento, jugos, varios tipos de té, café, infusiones, etc. Nuestro ambiente de consulta es relajante, y no buscamos formalidad, sino comodidad.",
    imageUrl: office,
  },

  {
    id: 3,
    title: "Confidencialidad",
    href: "#",
    description:
      "Mantenemos la privacidad y confidencialidad de nuestros pacientes. Marcamos todo lo que desean mantener en secreto de manera diferente en su archivo para darle un manejo confidencial diferente, que es confidencial pero no secreto. Puede ser comunicado o discutido con personas que forman parte del proceso en curso.",
    imageUrl: privacy,
  },
];

export default function Values() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Comodidad y seguridad
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Sección sobre cómo garantizamos que los pacientes se sientan cómodos
            y seguros durante las sesiones.
          </p>
        </div>
        <div className="justify-start mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-y-20 gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article
              key={post.id}
              className="flex flex-col items-start justify-between"
            >
              <div className="relative w-full">
                <img
                  src={post.imageUrl}
                  alt=""
                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="max-w-xl">
                <div className="group relative flex-col items-start">
                  <h3 className="mt-5 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <a href={post.href}>
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                  <p className="mt-5 text-sm leading-6 text-gray-600 line-clamp-3">
                    {post.description}
                  </p>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
}
