const faqs = [
  {
    question: "¿Existen facilidades de pago?",
    answer:
      "Sí, puedes cancelar a través de tus tarjetas Bancarias o de casas comerciales. (1, 4 hasta 36 cuotas)",
  },
  {
    question: "¿Cuáles son los medios de pago?",
    answer:
      "Puedes cancelar en efectivo, transferencia, tarjeas débito y crédito en 1, 4 hasta 36 cuotas.",
  },
  {
    question: "¿Cual es el horario de atención?",
    answer:
      "El horario habitual es de lunes a viernes de 10:00 a 18:00 hrs. Sin embargo, podemos atenderte en un día y horario diferente de común acuerdo si las condiciones así lo permiten.",
  },
  {
    question: "¿Trabajan con FONASA?",
    answer: "No, sólo atendemos particular.",
  },
  {
    question: "¿Puedo optar a reembolsos?",
    answer:
      "Sí, los reembolsos dependerán de tu plan de Isapre y/o de tu seguro complementario. En donde adjuntas la boleta y formulario de seguro de ser necesario.",
  },
  {
    question: "¿El psicólogo entrega licencias médicas?",
    answer:
      "No, el psicólogo no está facultado para entregar licencias médicas.",
  },
  {
    question: "¿Donde están ubicados?",
    answer:
      "Nuestra ubicación es en el edificio Centro de Negocios Maipú, a un costado de la estación Santiago Bueras del Metro línea 5. Av. Los pajaritos #3195, Piso 12, Oficina 1211, Maipú.",
  },
  {
    question: "¿El psicólogo entrega licencias médicas?",
    answer:
      "No, el psicólogo no está facultado para entregar licencias médicas.",
  },
];

export default function Example() {
  return (
    <div id="faq" className="bg-white">
      <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900">
              Preguntas frecuentes
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              No puedes encontrar la respuesta que buscabas? Comunícate con{" "}
              <a
                href="#"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                nuestro equipo de
              </a>{" "}
              soporte.
            </p>
          </div>
          <div className="mt-12 lg:col-span-2 lg:mt-0">
            <dl className="space-y-12">
              {faqs.map((faq) => (
                <div key={faq.question}>
                  <dt className="text-lg font-medium leading-6 text-gray-900">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">{faq.answer}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
